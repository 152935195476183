<template>
  <div class="page">
    <Viewheader :isHome="false" ref="navheader" />
    <!-- banner图 -->
    <!-- <BannerHead :title="bigTitle_1" :desc="smallTitle_1" :bannerImg="bannerImg"> -->
    <BannerHead :title="bigTitle_1" :desc="smallTitle_1">
      <div class="header-custom" slot="content">
        <img class="banner1" src="@/assets/img/imgWaterMark/banner-1.png" />
        <img class="banner2" src="@/assets/img/imgWaterMark/banner-2.png" />
        <img class="banner3" src="@/assets/img/imgWaterMark/banner-3.png" />
        <img class="banner4" src="@/assets/img/imgWaterMark/banner-4.png" />
        <img class="banner5" src="@/assets/img/imgWaterMark/banner-5.png" />
      </div>
    </BannerHead>

    <!-- 核心功能 -->
    <div class="core-function">
      <CommonTitle title="核心功能" />
      <ColumuTwo
        class="core-function_main"
        :list="spanList"
        :img="coreImg"
      ></ColumuTwo>
      <img class="core-line" src="@/assets/img/imgWaterMark/core-line.png" />
    </div>

    <!-- 能力展示 -->
    <div class="similar-main-wrap">
      <div class="similar-main">
        <CommonTitle title="能力展示" :desc="ability.desc" />
        <div class="operationWidth similar-main-cont">
          <div class="operationBackgroundColor">
            <!-- @success="getResultImg" -->
            <ImgUpload
              class="sc-img-upload"
              @select="selectPicture"
              @change="handleChange"
              @httpRequest="changeFile"
              :modelIndex.sync="imgCurrentActive"
              :imgList="exampleDiagram"
              :directionRow1280="true"
              :autoUpload="false"
              accept="image/jpeg,image/jpg,image/png"
              uploadText="选择一张"
            />
            <!-- <el-upload
            action=""
            accept="image/jpeg,image/jpg,image/png"
            :show-file-list="false"
            class="uploadFileType"
            list-type="picture-card"
            :on-success="getResultImg"
            :headers="headers"
            :on-change="handleChange"
            :http-request="changeFile"
          >
          </el-upload> -->

            <div class="operationRight">
              <div class="optimizationButton" v-if="afterOptimization">
                优化后
              </div>
              <div class="downloadButton" @click="imgDowns" v-if="down">
                下载
              </div>
              <!-- 成功图片 -->

              <div v-if="successImg" class="successImg">
                <!-- <img :src="this.resultGraphImg"> -->
                <div
                  class="sliderImg background-img"
                  :style="{ backgroundImage: `url(${this.oriImgUrl})` }"
                ></div>
                <div
                  class="sliderImg foreground-img"
                  :style="{ backgroundImage: `url(${this.resultGraphImg})` }"
                ></div>
                <input
                  type="range"
                  min="1"
                  max="100"
                  value="50"
                  class="slider-viewer"
                  id="slider"
                />
                <div class="trigger-gesture">
                  <img src="@/assets/img/imgWaterMark/ability-1.png" alt="" />
                </div>
                <div class="afterOptimization">优化前</div>
              </div>

              <!-- 报错图片 -->
              <div class="errMain" v-if="errImg">
                <div class="errImg">
                  <img src="../../assets/img/err.png" />
                </div>
                <div class="errText">
                  {{ errText }}
                </div>
              </div>

              <!-- 加载特效 -->
              <div class="loadSpecialEffects" v-if="loadImg"></div>
              <!-- 扫描脚框 -->
              <div class="top" v-if="loadImg"></div>
              <div class="bottom" v-if="loadImg"></div>
            </div>
          </div>
          <div class="actionText">
            <div class="detectionText">
              <input
                type="text"
                placeholder="粘贴网络图片URL"
                v-model="urlAdress"
              />
              <button
                class="detectionButton"
                @click="watermarkUrl(1)"
                :disabled="this.urlAdress == ''"
              >
                检测
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 差异优势 -->
    <!-- <Different :diffImg="diffImg" :diffList="diffList"></Different> -->

    <!-- 应用场景 -->
    <div class="application-scene">
      <CommonTitle title="应用场景" />
      <ColumuTwo
        class="application-scene_main"
        :list="appliList"
        :img="appImg"
      ></ColumuTwo>
    </div>

    <!-- 尾部 -->
    <Footering />
  </div>
</template>

<script>
// banner
import BannerHead from './components/Banner.vue';
import CommonTitle from './components/CommonTitle.vue';
import ColumuTwo from './components/ColumuTwo.vue';
import Different from '@/components/centerPage/differential_adv.vue';

import chayi from '@/assets/img/children/chayi.png';
import googleLogo from '@/assets/img/imgSimilar/google.png';
// 上传图片列表组件
import ImgUpload from '@/components/secondPage/imgUpload.vue';

// foote组件
// import Footering from "../../components/foot/viewfooter.vue";

// 公用组件
import '../../assets/css/public.css';
import '../../assets/css/imgPublic.css';
import '../../assets/css/imgPublic.css';
import '../../assets/css/commonModule.css';

import { apiUri } from '../../api/txt';
import { watermarkInpaint } from '@/api/aidata.js';

// 头部
import Viewheader from '../../components/viewheader/viewheader.vue';
// foote组件
import Footering from '@/components/foot/footer.vue';
export default {
  components: {
    BannerHead,
    CommonTitle,
    ColumuTwo,
    Different,
    Viewheader,
    Footering,
    ImgUpload,
  },
  data() {
    return {
      bannerImg: require('@/assets/img/imgWaterMark/banner-bg.png'),
      coreImg: require('@/assets/img/imgWaterMark/core-1.png'),
      bigTitle_1: '商品图片<span style="color: #6C38E0;">水印去除</span>',
      smallTitle_1: '去除商品图片水印，提高商品图片质量',

      // 核心功能
      spanList: [
        {
          title: '水印检测、涂抹',
          desc: '对电商商品图片中的水印进行位置检测，并对水印进行涂抹。',
        },
        {
          title:
            '接口<span style="color: #6C38E0;">批量处理</span>&<span style="color: #6C38E0;">结果评估</span>',
          desc: '支持商品图批量处理，并对处理结果的效果进行模型打分。',
        },
      ],

      //   差异优势
      diffImg: chayi,
      diffList: [
        {
          title: '暂无文案',
          desc: '暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案',
        },
        {
          title: '暂无文案',
          desc: '暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案',
        },
      ],
      // 能力展示
      ability: {
        desc: `模型结果对<img src='${googleLogo}' /> <span style='color:#6C38E0'>google 系列产品（shopping/⾕歌seo等）</span>，进⾏了针对性优化，在此场景下使⽤效果更优。模型⽬前仅⽀持png、jpg、jpeg。`,
      },

      //   应用场景
      appImg: require('@/assets/img/imgWaterMark/app-1.png'),
      appliList: [
        {
          title: '站外商品<span style="color: #6C38E0;">投放准入</span>',
          desc: '各站外广告投放平台 对于图片的准入标准都有“无水印”要求，批量对图片水印进行处理，提升商品入池比例。',
        },
        {
          title: '电商平台商品<span style="color: #6C38E0;">质量评估</span>',
          desc: '各电商平台对于含水印商品都会进行一定程度的曝光打压，批量对图片水印进行处理，优化图片质量，提升商品整体排名+点击转化。',
        },
      ],

      // 在线处理------------------------
      action: `${apiUri}/img/multiClearWatermark`,
      result_url: '',
      id: '',
      headers: {
        token: localStorage.getItem('tokenKey')
          ? localStorage.getItem('tokenKey')
          : '',
        isSample: 1,
      },

      // 示例图列表
      exampleDiagram: [
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/17744567832/rbnaemk1sm2anbwhaaoewjkcrug643.png',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/17744567832/rbvaqmihvi-aurb_aak104q29s8662.png',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/fy/watermark/7e1172a5-dcaf-4cd5-ad81-a625a766099a.jpg',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/17744567832/rbvaqmhowjeakfzcaagiz-j2ibu924.png',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/fy/watermark/dda45d13-81a6-4adc-9c5d-992c28e0fc77.jpg',
        },
      ],
      // 初始化选中状态
      imgCurrentActive: 0,
      // 初始化选中图
      resultGraphImg:
        'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/17744567832/rbnaemk1sm2anbwhaaoewjkcrug643.png',
      // 成功图
      successImg: false,
      // 失败图
      errImg: true,
      // 正在加载
      loadImg: true,
      // 加载文案
      errText: '处理中....',
      urlAdress: '',
      // 加载时，禁止点击
      prohibitClicking: false,
      // 优化后
      afterOptimization: false,
      // 下载
      down: false,
      isSample: '',
    };
  },
  computed: {},
  mounted() {
    // 默认点击高亮
    this.selectPicture(this.imgCurrentActive, { img: this.resultGraphImg });
  },
  methods: {
    // 点击选中高亮
    selectPicture(index, { img }) {
      this.resultGraphImg = img;
      this.imgCurrentActive = index;
      this.watermarkUrl(0, this.resultGraphImg);
    },
    // 上传图片去水印
    changeFile(files) {
      this.watermarkUrl(1, '', {
        imgFile: files,
      });
    },
    // 去除水印url  请求
    watermarkUrl(status, resultGraphImg, data) {
      // if (status == 1) {
      //   this.$message.info('暂不支持自定义上传');
      //   return;
      // }
      // 成功图
      this.successImg = false;
      // 失败图
      this.errImg = true;
      // 正在加载
      this.loadImg = true;
      this.errText = '处理中....';
      //优化后
      this.afterOptimization = false;
      // 下载
      this.down = false;
      if (status == 0) {
        // 点击图片获取地址
        var imgUrl = resultGraphImg;
        // 加载时禁止点击切换
        this.prohibitClicking = true;
        this.isSample = 0;
      } else {
        // 点击检测按钮  获取地址
        var imgUrl = this.urlAdress;
        this.imgCurrentActive = -1;
        this.isSample = 1;
      }

      // 请求接口-------------------
      watermarkInpaint({
        imgUrl: imgUrl || '',
        isSample: this.isSample,
        ...data,
      }).then(({ data }) => {
        console.log(111);
        if (data.state === '0x0000') {
          console.log(data);
          // setTimeout(() => {
          this.urlAdress = '';
          // 成功图
          this.successImg = true;
          this.oriImgUrl = data.data.oriImgUrl;
          this.resultGraphImg = data.data.hasWatermark
            ? data.data.resultUrl
            : data.data.oriImgUrl;

          // 失败图
          this.errImg = false;
          // 正在加载
          this.loadImg = false;
          // 加载完成  可点击切换
          this.prohibitClicking = false;
          //优化后
          this.afterOptimization = true;
          if (status == 1) {
            // 下载
            this.down = true;
          }

          this.$nextTick(function () {
            this.method.dragDropImages();
          });
          // }, 1000);
        } else if (data.state === '0x0008') {
          this.$message.error(data.message);
          this.$refs.navheader.logOn();
          // }else if(data.state === "00001"){
        } else {
          setTimeout(() => {
            this.urlAdress = '';
            // 成功图
            this.successImg = false;
            // 失败图
            this.errImg = true;
            this.errText = data.message;
            this.loadImg = false;
            // 加载完成  可点击切换
            this.prohibitClicking = false;
            //优化后
            this.afterOptimization = false;
            // 下载
            this.down = false;
          }, 1000);
        }
      });
    },
    // 图片change操作
    handleChange(file, fileList) {
      // return;
      // 成功图
      this.successImg = false;
      // 失败图
      this.errImg = true;
      // 正在加载
      this.loadImg = true;
      this.errText = '处理中....';
      this.imgCurrentActive = -1;
      // 加载时禁止点击切换
      this.prohibitClicking = true;

      //优化后
      this.afterOptimization = false;
      // 下载
      this.down = false;
    },
    // 图片上传成功后的回调
    getResultImg(response, file, fileList) {
      if (response.state === '0x0000') {
        setTimeout(() => {
          this.oriImgUrl = response.data.oriImgUrl;
          this.resultGraphImg = response.data.resultUrl;
          // 成功图
          this.successImg = true;
          // 失败图
          this.errImg = false;
          // 正在加载
          this.loadImg = false;
          // 加载完成  可点击切换
          this.prohibitClicking = false;

          //优化后
          this.afterOptimization = true;
          // 下载
          this.down = true;
          this.$nextTick(function () {
            this.method.dragDropImages();
          });
        }, 1000);
      } else if (response.state === '0x0008') {
        this.$message.error(response.message);
        this.$refs.navheader.logOn();
      } else {
        setTimeout(() => {
          // 成功图
          this.successImg = false;
          // 失败图
          this.errImg = true;
          this.errText = response.message;
          this.loadImg = false;
          // 加载完成  可点击切换
          this.prohibitClicking = false;
          //优化后
          this.afterOptimization = false;
          // 下载
          this.down = false;
        }, 1000);
      }
    },
    // 结果导出
    imgDowns() {
      const a = document.createElement('a');
      this.getLocalUrl(this.resultGraphImg).then((res) => {
        a.href = res;
        a.download = '水印去除' + Date.now();
        a.click();
      });
    },
    getLocalUrl(url) {
      // 图片加载非同步，需要promise包裹
      return new Promise((resolve, reject) => {
        // 创建图片，并将图片属性设置为可跨域
        const img = document.createElement('img');
        // 不设置该属性canvas无法将图片转为base64
        img.setAttribute('crossorigin', 'anonymous');
        img.src = url;
        img.onload = function () {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctr = canvas.getContext('2d');
          // 画图
          ctr.drawImage(img, 0, 0, img.width, img.height);
          resolve(canvas.toDataURL());
        };
      });
    },
  },
};
</script>

<style lang="less" scoped>
// banner
.header-custom {
  width: 100%;
  margin: auto;
  height: 100%;
  position: absolute;
  display: flex;
  left: 50%;
  transform: translate(-50%);
  align-items: flex-end;
  padding-bottom: 2.8%;
  box-sizing: border-box;
  justify-content: center;
  img {
  }
  @time: 0.7s;
  .banner1 {
    width: 21%;
    bottom: 32%;
    left: 5%;
    margin-right: 1%;
  }
  .banner2 {
    width: 21%;
    bottom: 32%;
    left: 27%;
    margin-right: 1%;
  }
  .banner3 {
    width: 7.6%;
    bottom: 32%;
    left: 50%;
  }
  .banner4 {
    width: 21%;
    bottom: 19%;
    left: 5%;
    margin-right: 1%;
  }
  .banner5 {
    width: 13.4%;
    bottom: 19%;
    left: 19.3%;
  }
}

.application-scene_main {
  margin-top: 80px;
}
/* 核心 */
.core-function {
  position: relative;
  padding: 60px 0 140px;
  .core-function_main {
    margin-top: 245px;
  }
  /deep/ .common-two-column_img {
    width: 49.1%;
    margin-top: -160px;
    margin-right: 4px;
    z-index: 1;
  }
  /deep/ .common-two-column {
    column-gap: 215px;
  }
  .core-line {
    position: absolute;
    width: 100vw;
    top: 8%;
    left: 50%;
    transform: translateX(-50%);
  }
}
/* 应用场景 */
.application-scene {
  padding-top: 80px;
  padding-bottom: 110px;
  /deep/ .common-two-column {
    column-gap: 28px;
  }
  /deep/ .common-two-column_img {
    width: 48.9%;
    margin-right: 22px;
    margin-top: -106px;
  }
}
// 能力展示
.similar-main-wrap {
  padding: 80px 0;
  width: 100%;
  background: #f0f2f5;
  /deep/ .common-text {
    img {
      vertical-align: middle;
      display: inline-block;
      margin: -2px 3px 0;
      width: 24px;
    }
  }
  .operationBackgroundColor {
    column-gap: 16px;
    height: calc(100% - 62px);
  }
  .operationRight {
    height: 100%;
    border-radius: 8px;
  }
  .similarityGraph {
    width: 32.8%;
    background: #d8d8d8;
    border-radius: 8px;
  }
  .operationCenter {
    width: 71.25%;
    height: auto;
    margin-left: 16px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: fit-content;
    }
  }
  .successImgProduct {
    width: 100%;
  }
  .operationYellowRight {
    flex: 1;
    flex-shrink: 0;
    height: 100%;
    margin: 7px 0 0 24px;
  }
  .recognitionResult {
    margin: 0;
    font-size: 18px;
    color: #000108;
    span {
      color: #ff8645;
    }
  }
  .detectionButton {
    background: rgba(108, 56, 224, 0.1);
    color: rgba(108, 56, 224, 1);
  }
  .confidenceLevel {
    margin: 24px 0 0;
    font-size: 14px;
  }
  .similarityGraphMain {
    height: 100%;
    justify-content: space-between;
    row-gap: 0.8%;
    column-gap: 0.8%;
  }
  .similarityGraphImg img {
    border-radius: 8px;
    overflow: hidden;
  }
  .successImg {
    height: 100%;
  }
  .afterOptimization,
  .optimizationButton {
    margin-top: 14px;
    background: rgba(255, 255, 255, 0.5);
    padding: 7px 12px;
    border-radius: 4px;
    width: fit-content;
    height: fit-content;
    line-height: inherit;
    top: 0;
    white-space: nowrap;
  }
}
.similar-main-cont {
  // display: flex;
  width: 100%;
  height: 770px;
  margin: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  box-sizing: border-box;
}
@media screen and(max-width:1280px) {
  .similar-main-wrap {
    .operationBackgroundColor {
      display: block;
    }
    .operationRight {
      margin-top: 20px;
      height: 90vw;
      max-height: 676px;
    }
  }
  .similar-main-cont {
    height: fit-content;
  }
}
@media screen and(max-width:990px) {
  .core-function {
    padding: 60px 0 70px;
  }
  .trigger-gesture {
    left: calc(49.7% - 20px);
  }
}
@media screen and (min-width: 1440px) {
  .header-custom {
    width: 1440px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
